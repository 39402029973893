import gql from 'graphql-tag';
import { ImageFields, VideoFields } from '../common-fields.queries';

export const GroupContentFields = gql`
	fragment GroupContentFields on GroupContent {
		title
		items {
			image {
				id
				description
				imageType
			}
			title
			url
			facets {
				groupName
				id
				value
				range {
					min
					max
				}
			}
		}
	}
`;

export const BrandContentFields = gql`
	${ImageFields}
	fragment BrandContentFields on CategoryCard {
		__typename
		id
		image {
			...ImageFields
		}
		url
		name
		trackingCode
		type
	}
`;

export const ArticleContentFields = gql`
	${ImageFields}
	${VideoFields}
	fragment ArticleContentFields on ArticleCard {
		__typename
		id
		url
		title
		type
		image {
			...ImageFields
		}
		video {
			...VideoFields
		}
		trackingCode
	}
`;

export const ContentGroupsFields = gql`
	fragment ContentGroupsFields on ContentGroups {
		title
		items {
			... on CategoryCard {
				id
				name
				url
				image {
					description
					id
					imageType
				}
			}
		}
	}
`;

export const CategoryPageFields = gql`
	${BrandContentFields}
	${ContentGroupsFields}
	${ArticleContentFields}
	${ImageFields}
	${VideoFields}
	fragment CategoryPageFields on CategoryPageContent {
		__typename
		id
		brandCopy
		brands {
			...BrandContentFields
		}
		categories {
			...ContentGroupsFields
		}
		articles {
			...ArticleContentFields
		}
		videos {
			...VideoFields
		}
		seoText
		general {
			__typename
			... on ContentRichText {
				value
			}
			... on GeneralGroupContent {
				items {
					... on GeneralImageItem {
						image {
							...ImageFields
						}
						title
						url
					}
					... on GeneralVideoItem {
						video {
							...VideoFields
						}
						title
					}
				}
			}
			... on ContentCallToAction {
				name
				url
			}
		}
	}
`;

export const CategoryDropPageFields = gql`
	${BrandContentFields}
	${ContentGroupsFields}
	${ArticleContentFields}
	${ImageFields}
	${VideoFields}
	fragment CategoryDropPageFields on CategoryDropPageContent {
		__typename
		id
		brands {
			...BrandContentFields
		}
		categories {
			...ContentGroupsFields
		}
		articles {
			...ArticleContentFields
		}
		videos {
			...VideoFields
		}
		seoText
		general {
			__typename
			... on ContentRichText {
				value
			}
			... on GeneralGroupContent {
				items {
					... on GeneralImageItem {
						image {
							...ImageFields
						}
						title
						url
					}
					... on GeneralVideoItem {
						video {
							...VideoFields
						}
						title
					}
				}
			}
			... on ContentCallToAction {
				name
				url
			}
		}
	}
`;

export const CommonPromoFields = gql`
	${VideoFields}
	${ImageFields}
	fragment CommonPromoFields on ContentPromo {
		id
		priority
		variation
		action {
			__typename
			... on Link {
				name
				url
			}
			... on VideoLink {
				name
				video {
					...VideoFields
				}
			}
		}
		couponCode
		expirationDate
		headline
		subHeading
		image {
			...ImageFields
		}
		imageOverlay {
			...ImageFields
		}
		trackingCode
		calculatorType
	}
`;

export const SHARED_CATEGORY_PROMOS = gql`
	${CommonPromoFields}

	query SharedCategoryPromos($categoryId: Int!) {
		sharedCategoryPromos(id: $categoryId) {
			...CommonPromoFields
		}
	}
`;

export const CATEGORY_BROWSE_PAGE_CONTENT = gql`
	${CommonPromoFields}
	${CategoryPageFields}

	query CategoryBrowsePageContent($categoryId: Int!) {
		sharedCategoryPromos(id: $categoryId) {
			...CommonPromoFields
		}
		categoryContent(id: $categoryId) {
			... on CategoryPageContent {
				...CategoryPageFields
			}
		}
	}
`;

export const CATEGORY_PLP_CONTENT = gql`
	${CommonPromoFields}
	${CategoryDropPageFields}
	${GroupContentFields}

	query CategoryPLPContent($categoryId: Int!) {
		sharedCategoryPromos(id: $categoryId) {
			...CommonPromoFields
		}

		categoryContent(id: $categoryId) {
			... on CategoryDropPageContent {
				...CategoryDropPageFields
				groupContent {
					...GroupContentFields
				}
			}
			... on CategoryPageContent {
				id
			}
		}
	}
`;
